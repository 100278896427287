import {
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { TegelModule } from '@scania/tegel-angular';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { AppService } from '../../../app.service';
import { SubjectService } from '../../../services/subject.service';

@Component({
  selector: 'app-parts-logistics',
  standalone: false,
  templateUrl: './parts-logistics.component.html',
  styleUrl: './parts-logistics.component.css',
})
export class PartsLogisticsComponent implements OnChanges, OnInit {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() infoBlockId: string = '';
  constructor(
    public utilService: UtilsService,
    public appService: AppService,
    public companyFormService: CompanyFormService,
    private subjectService: SubjectService
  ) {}
  partsLogisticForm =
    inject(CompanyFormService).form.controls.partsLogisticForm;

  customerNoFocus: boolean = false;
  invoiceReeiverNoFocus: boolean = false;

  get deliveryCustomerNo() {
    return this.partsLogisticForm.get('deliveryCustomerNo');
  }

  get invoiceReceiverNo() {
    return this.partsLogisticForm.get('invoiceReceiverNo');
  }

  ngOnInit(): void {
    this.subjectService.validationCompanySubject.subscribe((data) => {
      if (data) {
        this.customerNoFocus = true;
        this.invoiceReeiverNoFocus = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.companyFormService.checkAllFormValidationDetails()) {
      this.partsLogisticForm.controls.deliveryCustomerNo.addValidators(
        Validators.required
      );
    } else {
      this.partsLogisticForm.controls.deliveryCustomerNo.removeValidators(
        Validators.required
      );
    }

    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.partsLogisticForm.patchValue({
        deliveryCustomerNo:
          this.companyData?.CompanyPartsLogistics?.DeliveryCustomerNo,
        invoiceReceiverNo: this.companyData?.CompanyPartsLogistics?.CustomerNo,
        distributorCustomerNo: this.companyData?.CompanyPartsLogistics
          ?.distributorCustomerNo
          ? this.companyData?.CompanyPartsLogistics?.distributorCustomerNo
          : 'Not Set',
      });
    } else {
      this.partsLogisticForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      this.partsLogisticForm.patchValue({
        deliveryCustomerNo:
          this.companyData?.CompanyPartsLogistics?.DeliveryCustomerNo,
        invoiceReceiverNo: this.companyData?.CompanyPartsLogistics?.CustomerNo,
        distributorCustomerNo: 'Not Set',
      });
    }
  }

  getLabelText(labelId:string,infoBlockId:string){
    return this.appService.getLabelText(labelId,infoBlockId)
  }

  getPopupHelpText(labelId:string,infoBlockId:string){
    return this.appService.getPopupHelpText(labelId,infoBlockId)
  }

  get Required(){
    return this.companyFormService.checkAllFormValidationDetails();
  }

  saveDraft() {
    if(!this.companyData.CompanyPartsLogistics)
      this.companyData.CompanyPartsLogistics = {};
    
    this.companyData.CompanyPartsLogistics.DeliveryCustomerNo =
      this.partsLogisticForm.get('deliveryCustomerNo')?.value;
    this.companyData.CompanyPartsLogistics.CustomerNo =
      this.partsLogisticForm.get('invoiceReceiverNo')?.value;
    if (this.partsLogisticForm?.dirty) {
      this.companyData.CompanyPartsLogistics.IsUpdated = true;
    }
    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}
