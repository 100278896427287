import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { of, retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}
  getCategories() {
    return this.http.get(environment.baseUrl + '/v1/category').pipe(retry(3));
  }

  async getCountriesWithScaniaAssistance() {
    return this.http
      .get(environment.baseUrl + '/v1/country/has-scania-assistance')
      .pipe(retry(3))
      .toPromise();
  }

  getCategoryDetails(id: string) {
    return this.http
      .get(environment.baseUrl + '/v1/category/' + id)
      .pipe(retry(3));
  }

  getCompanyList(categoryName: string) {
    let url: string = '';
    switch (categoryName) {
      case 'Distributor':
        url = environment.baseUrl + '/v1/company/list/distributors';
        break;
      case 'Deleted':
        url = environment.baseUrl + '/v1/company/list/deleted';
        break;
      default:
        url = environment.baseUrl + '/v1/company/list/' + categoryName;
        break;
    }
    return this.http.get(url).pipe(retry(3));
  }

  getCompanyDetails(id: string) {
    return this.http
      .get(environment.baseUrl + '/v1/company/' + id)
      .pipe(retry(3));
  }

  getProductAndServices() {
    return this.http.get(environment.baseUrl + '/v1/dealerworkshopservice');
  }

  getPaymentMethods() {
    return this.http.get(environment.baseUrl + '/v1/paymentcards');
  }

  getDistributorImports() {
    return this.http.get(environment.baseUrl + '/v1/distributorimporttype');
  }

  getDistributorOptions() {
    return this.http.get(environment.baseUrl + '/v1/distributoroption');
  }

  getGlobalMgmtTypeCode() {
    return this.http.get(environment.baseUrl + '/v1/globalmanagementtype');
  }

  getAssistanceRoleTypes() {
    return this.http.get(environment.baseUrl + '/v1/assistancerolestype');
  }

  getAssistanceProdServiceTypes() {
    return this.http.get(
      environment.baseUrl + '/v1/assistanceproductservicetype'
    );
  }

  getCompanyByDealerAndCountry(dealerId: string, country: string) {
    return this.http.get(
      environment.baseUrl + '/v1/companydealerworkshop/' + dealerId + '/' + country,
    );
  }
  updateCompany(requestBody: any, id: string) {
    return this.http.post(
      environment.baseUrl + '/v1/company/' + id + '/details',
      requestBody
    );
  }

  createCompany(requestBody: string) {
    return this.http.put(environment.baseUrl + '/v1/company', requestBody);
  }

  Delete(id: string) {
    return this.http.delete(environment.baseUrl + '/v1/company/' + id);
  }

  GetHistory(id: string) {
    return this.http.get(environment.baseUrl + `/v1/company/${id}/history`);
  }

  MovetoCategory(id: string, categoryid: string) {
    return this.http.put(
      environment.baseUrl + `/v1/company/${id}/move/${categoryid}`,
      null
    );
  }

  export(request: any) {
    return this.http.get(
      environment.baseUrl +
        `/v1/company/export/${request.categoryid}/${request.type}?filters=${request.filterdata}`,
      { observe: 'response', responseType: 'blob' }
    );
  }

  getDealerRelationTree(
    companyId: string,
    relationType: string = 'sisnetworkparent'
  ) {
    return this.http.get(
      environment.baseUrl + `/v1/companyrelation/${companyId}/${relationType}`
    );
  }

  getDealerRelationTreeByDealerId(
    companyId: string,
    dealerId: string,
    relationType: string = 'sisnetworkparent'
  ) {
    return this.http.get(
      environment.baseUrl +
        `/v1/companyrelation/${companyId}/${relationType}/${dealerId}`
    );
  }

  getTagGroup() {
    return this.http.get(environment.baseUrl + '/v1/taggroup').pipe(retry(3));
  }

  getLabelTexts() {
    return this.http.get(environment.baseUrl + '/v1/labeltext').pipe(retry(3));
  }

  getTagGroupValue(id: any) {
    return this.http
      .get(environment.baseUrl + '/v1/taggroup/' + id)
      .pipe(retry(3));
  }

  getUsedvehicleandpowersolutions() {
    return this.http
      .get(environment.baseUrl + '/v1/usedvehicleandpowersolutions')
      .pipe(retry(3));
  }

  getSocialmediatype() {
    return this.http
      .get(environment.baseUrl + '/v1/socialmediatype')
      .pipe(retry(3));
  }

  hasDealerAccesstoCompany(
    companyId: string,
    country: string,
    dealerId: string,
    categoryId: string
  ) {
    return this.http
      .get(
        environment.baseUrl +
          `/v1/companydealerworkshop/dealer-id-exists-on-other-company/${companyId}/${country}/${dealerId}/${categoryId}`
      )
      .pipe(retry(3));
  }

  getDealerAccessToCompany(companyId: string) {
    return this.http.get(
      environment.baseUrl +
        `/v1/companyrelation/dealers-and-distributors/${companyId}`
    );
  }

  getParentCompaniesCountries(companyIds: string) {
    return this.http.get(
      environment.baseUrl +
        `/v1/companyrelation/get-parents-countries/${companyIds}`
    );
  }
}
