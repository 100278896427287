import { Component, inject, Input, SimpleChanges } from '@angular/core';
import { AppService } from '../../../app.service';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';

@Component({
  selector: 'app-emergency-breakdown-charges',
  standalone: false,
  templateUrl: './emergency-breakdown-charges.component.html',
  styleUrl: './emergency-breakdown-charges.component.css',
})
export class EmergencyBreakdownChargesComponent {
  @Input() companyId: string = '';
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any = {};
  @Input() infoBlockId: string = '';
  emergencyBreakDownChargesForm =
    inject(CompanyFormService).form.controls.CompanyAssistanceWorkoutCharges;
  textFocus = false;
  textFocusCalloutfee = false;

  constructor(
    public utilService: UtilsService,
    public appService: AppService
  ) {}

  get AfterHourCompensation() {
    return this.emergencyBreakDownChargesForm.get('AfterHourCompensation');
  }

  get CalloutFee() {
    return this.emergencyBreakDownChargesForm.get('CalloutFee');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.emergencyBreakDownChargesForm.patchValue({
        AfterHourCompensation:
          this.companyData?.CompanyAssistanceWorkoutCharges
            ?.AfterHourCompensation,
        CalloutFee:
          this.companyData?.CompanyAssistanceWorkoutCharges?.CalloutFee,
        Company_id: this.companyData?.Id,
      });
    } else {
      this.emergencyBreakDownChargesForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      this.emergencyBreakDownChargesForm.patchValue({
        AfterHourCompensation:
          this.companyData?.CompanyAssistanceWorkoutCharges
            ?.AfterHourCompensation,
        CalloutFee:
          this.companyData?.CompanyAssistanceWorkoutCharges?.CalloutFee,
        Company_id: this.companyData?.Id,
      });
      if (!this.companyData.CompanyAssistanceWorkoutCharges) {
        this.companyData.CompanyAssistanceWorkoutCharges = {};
      }
    }
  }

  getLabelText(labelId: string, infoBlockId: string) {
    return this.appService.getLabelText(labelId, infoBlockId);
  }

  getPopupHelpText(labelId: string, infoBlockId: string) {
    return this.appService.getPopupHelpText(labelId, infoBlockId);
  }

  saveDraft() {
    if (!this.companyData.CompanyAssistanceWorkoutCharges) {
      this.companyData.CompanyAssistanceWorkoutCharges = {};
    }
    this.companyData.CompanyAssistanceWorkoutCharges.AfterHourCompensation =
      this.emergencyBreakDownChargesForm.get('AfterHourCompensation')?.value;
    this.companyData.CompanyAssistanceWorkoutCharges.CalloutFee =
      this.emergencyBreakDownChargesForm.get('CalloutFee')?.value;

    if (this.emergencyBreakDownChargesForm.dirty) {
      this.companyData.CompanyAssistanceWorkoutCharges.IsUpdated = true;
    }

    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}
