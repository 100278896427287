import { Component, computed, effect, input, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TegelModule } from '@scania/tegel-angular';
import { SubjectService } from '../../services/subject.service';

import {
  FontAwesomeModule,
  IconDefinition,
} from '@fortawesome/angular-fontawesome';
import { CompanyService } from '../../services/company.service';
import { AppService } from '../../app.service';
import { InfoblockService } from '../../services/infoblock.service';
import { CountryService } from '../../services/country.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user.model';
import { forkJoin } from 'rxjs';
import {
  categoryList,
  menuItem,
  Taggroup,
  userDataList,
} from '../../models/shared.model';
import { countryList } from '../../models/shared.model';
import { Infoblock } from '../../models/infoblock.model';
import { TagGroupService } from '../../services/tag-group.service';

@Component({
  selector: 'app-left-menu',
  standalone: true,
  imports: [TegelModule, FontAwesomeModule, RouterLink],
  templateUrl: './left-menu.component.html',
  styleUrl: './left-menu.component.css',
})
export class LeftMenuComponent implements OnInit {
  accessAct: boolean = false;
  adminAct: boolean = false;
  isAuthenticated = false;
  userName = '';
  email? = '';
  user: User | undefined;
  dashboardMenuItem: any = {
    Name: 'Dashboard',
    Title: 'Dashboard',
    url: '',
    image: '/images/dashboard.svg',
    isActive: true,
  };

  globalManagementListMenuItem: any = {
    Name: 'GlobalManagementList',
    Title: 'Global Management List',
    url: 'globalManagementList',
    image: '/images/dashboard.svg',
    isActive: false,
  };

  administrationMenuItem: any = {
    Name: 'Administration',
    Title: 'Administration',
    url: 'administration',
    image: '/images/administration.svg',
    submenuItem: [
      {
        title: 'addressTypes',
        url: '/addressTypes',
        description: 'Address Types',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'phoneTypes',
        url: '/phoneTypes',
        description: 'Phone Types',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'contactTypes',
        url: '/contactTypes',
        description: 'Contact Types',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'categories',
        url: '/categories',
        description: 'Categories',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'dealerWorkshopTypes',
        url: '/dealerWorkshopTypes',
        description: 'DealerWorkshop Types',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'dealerWorkshopOwnership',
        url: '/dealerWorkshopOwnership',
        description: 'DealerWorkshop Ownership',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'distributorImportsTypes',
        url: '/distributorImportsTypes',
        description: 'Distributor imports types',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'distributorOwnership',
        url: '/distributorOwnership',
        description: 'Distributor Ownership',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'infoblocks',
        url: '/infoblocks',
        description: 'Infoblocks ',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'texts',
        url: '/texts',
        description: 'Texts',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'tagGroups',
        url: '/tagGroups',
        description: 'Tag groups',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'labelTexts',
        url: '/labelTexts',
        description: 'Label Texts',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'services',
        url: '/services',
        description: 'Services',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'countries',
        url: '/countries',
        description: 'Countries',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'globalManagementListTypes',
        url: '/globalmanagementlisttypes',
        description: 'Global Management List Types',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'paymentcards',
        url: '/paymentcards',
        description: 'Payment Cards',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'relationTypes',
        url: '/relationTypes',
        description: 'Relation Types',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'scaniaAssistance',
        url: '/scaniaAssistanceListTypes',
        description: 'Scania Assistance List Types',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'productServicesListTypes',
        url: '/assistanceProductServiceListTypes',
        description: 'Product/Services List Types',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'batchlist',
        url: '/batchlist',
        description: 'Batches',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
      {
        title: 'usermanual',
        url: '/usermanual',
        description: 'User Manual',
        isActive: false,
        flag: 'admin',
        image: '/images/administration.svg',
      },
    ],
  };

  userMgmtMenuItem: any = {
    title: 'users',
    url: '/users',
    description: 'Users',
    isActive: false,
    flag: 'accessControl',
    image: '/images/accesscontrol.svg',
  };
  accountTypeMgmtMenuItem: any = {
    title: 'accountTypes',
    url: '/accountTypes',
    description: 'Account Types',
    isActive: false,
    flag: 'accessControl',
    image: '/images/accesscontrol.svg',
  };
  accessControlMenuItem: any = {
    Name: 'AccessControl',
    Title: 'Access Control',
    url: 'accessControl',
    image: '/images/accesscontrol.svg',
    submenuItem: [],
  };

  orgCountryList: countryList[];
  categoryList: menuItems[];
  infoBlockData: Infoblock[];
  tagGroupList: Taggroup[];
  currentUrl: string;

  constructor(
    private router: Router,
    private subjectService: SubjectService,
    private categoryService: CompanyService,
    private appService: AppService,
    private infoblockService: InfoblockService,
    private countryService: CountryService,
    private authService: AuthService,
    private tagGroupService: TagGroupService
  ) {
    // Register a new effect.

    this.subjectService.leftMenuNavSubject.subscribe((data: boolean) => {
      this.mobileNavOpen = data;
    });
    this.isAuthenticated = this.authService.isAuthenticated;
    this.userName =
      this.authService.identityClaims?.given_name +
      ' ' +
      this.authService.identityClaims?.family_name;
    this.email = this.authService.identityClaims?.email;
  }
  ngOnInit(): void {
    this.authService
      .isAuthenticatedObservable()
      .subscribe((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
        this.userName =
          this.authService.identityClaims?.given_name +
          ' ' +
          this.authService.identityClaims?.family_name;
        this.email = this.authService.identityClaims?.email;
      });

    this.authService.isUserDetailsLoadedObservable().subscribe((loaded) => {
      this.loadMenuItems();
    });
  }

  loadMenuItems() {
    var isAdmin = this.appService.User?.IsAdmin();
    this.user = this.appService.User;
    this.router.events.subscribe((event: any) => {
      this.currentUrl = event.url;
    });

    forkJoin({
      data: this.infoblockService.getInfoblocks(),
      countries: this.countryService.getCountries(),
      categories: this.categoryService.getCategories(),
      tagGroups: this.tagGroupService.getTagGroupList(),
    }).subscribe(({ data, countries, categories, tagGroups }) => {
      this.infoBlockData = data as Infoblock[];
      this.categoryList = categories as menuItems[];
      this.orgCountryList = countries as countryList[];
      this.tagGroupList = tagGroups as Taggroup[];
      this.appService.storeInfoBlockdata(this.infoBlockData);
      this.appService.storeCountries(this.orgCountryList);

      if (this.categoryList && this.categoryList.length) {
        this.categoryList.sort(
          (a: any, b: any) => Number(a.DisplayOrder) - Number(b.DisplayOrder)
        );

        this.appService.storeCategories(this.categoryList);
        //set current category from URL path or first item
        this.setCategoryFromUrlOrDefault(this.categoryList);
        this.paintMenuItems(this.categoryList, this.infoBlockData);
      } else {
        console.log('No Data Avilable');
      }
    });
  }

  mobileNavOpen = false;
  navOpen = false;
  is404page: boolean = false;
  menuItems: menuItems[] = [];

  paintMenuItems(categories: menuItems[], allInfoBlocks: Infoblock[]) {
    if (this.user) {
      categories.map((item: any) => {
        item.url = 'company/' + item.Name;
        item.image = item.Name;
        item.isActive = false;
      });

      categories = categories.filter((category: any) =>
        this.user?.HasAccessToCategory(category.Id)
      );

      if (!this.user.IsScaniaAssistanceCoordinator())
        this.menuItems = [this.dashboardMenuItem, ...categories];
      else this.menuItems = [...categories];

      if (this.user.IsSISCordinator()) {
        this.accessControlMenuItem.submenuItem = [];
        this.accessControlMenuItem.submenuItem.push(this.userMgmtMenuItem);
        this.menuItems.push(this.accessControlMenuItem);
      }
      if (this.user.IsDOSCordinator()) {
        this.accessControlMenuItem.submenuItem = [];
        this.accessControlMenuItem.submenuItem.push(this.userMgmtMenuItem);
        this.menuItems.push(this.accessControlMenuItem);
      }
      if (this.user.IsAdmin()) {
        this.accessControlMenuItem.submenuItem = [];
        this.accessControlMenuItem.submenuItem.push(this.userMgmtMenuItem);
        this.accessControlMenuItem.submenuItem.push(
          this.accountTypeMgmtMenuItem
        );

        this.menuItems.push(this.globalManagementListMenuItem);
        this.menuItems.push(this.accessControlMenuItem);
        this.menuItems.push(this.administrationMenuItem);
      } else if (categories && categories.length) {
        if (
          categories.some((item: any) => {
            if (item.Leftinfoblock_Ids)
              item.Leftinfoblock_Ids = item.Leftinfoblock_Ids.split(',');
            if (item.Rightinfoblock_Ids)
              item.Rightinfoblock_Ids = item.Rightinfoblock_Ids.split(',');
            return this.user?.hasInfoBlockName(
              'GlobalManagementList',
              this.infoBlockData,
              [...item.Leftinfoblock_Ids, ...item.Rightinfoblock_Ids]
            );
          })
        ) {
          if (!this.user?.IsScaniaAssistanceCoordinator())
            //dont show global mgmt report for SAC
            this.menuItems.push(this.globalManagementListMenuItem);
        }

        if (this.user && this.user.Loginname !== undefined) {
          let adminTagGroup = this.tagGroupList.some((item: Taggroup) => {
            return item.UsersAllowedToAdminister?.some(
              (loginname: string) =>
                this.user?.Loginname.toLowerCase() == loginname.toLowerCase()
            );
          });

          if (adminTagGroup) {
            let adminMenuItem = JSON.parse(
              JSON.stringify(this.administrationMenuItem)
            );
            adminMenuItem.submenuItem = adminMenuItem.submenuItem.filter(
              (item: any) =>
                item.title === 'labelTexts' || item.title == 'tagGroups'
            );

            this.menuItems.push(adminMenuItem);
          }
        }
      }
      //Highlight current item
      this.menuItems.forEach((item: any) => {
        let url = '/' + item.url;
        if (item.Name === 'AccessControl' || item.Name === 'Administration') {
          var subUrl;
          for (let i of item.submenuItem) {
            subUrl = '/' + item.url + i.url;
            if (subUrl === this.currentUrl) {
              i.isActive = true;

              if (item.Name === 'AccessControl') {
                this.accessAct = true;
                this.adminAct = false;
              } else {
                this.accessAct = false;
                this.adminAct = true;
              }
            } else {
              i.isActive = false;
            }
          }
        }
        if (
          url.toLowerCase() ===
          this.currentUrl?.toLowerCase().replaceAll('%20', ' ')
        ) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
      });
    }
  }
  setCategoryFromUrlOrDefault(categories: any) {
    let url = window.location.href;
    if (url && url.toLowerCase().includes('/company/')) {
      let paths: any = url.split('/');
      let categoryName = paths.pop() || paths.pop(); // handle trailing slash

      var category = categories.find((item: any) => item.Name == categoryName);

      if (category) this.storeCurrentCategory(category);
      else this.storeCurrentCategory(categories[0]);
    } else {
      //set first item as selected category
      this.storeCurrentCategory(categories[0]);
    }
  }
  onSubRoute(url: string): boolean {
    return window.location.href.includes(url);
  }
  toggleMobileSideMenu() {
    this.mobileNavOpen = !this.mobileNavOpen;
    this.subjectService.toggleLeftMenuNavSubject(this.mobileNavOpen);
  }

  toggleSideMenu() {
    this.navOpen = !this.navOpen;
    this.subjectService.toggleSideMenuNavSubject(this.navOpen);
  }

  isActive(url: string): boolean {
    return this.router.url === url;
  }

  navigetAdminUrl(item: any) {
    this.menuItems.forEach((item: any) => {
      item.isActive = false;
      if (item.submenuItem) {
        item.submenuItem.forEach((obj: any) => (obj.isActive = false));
      }
    });
    item.isActive = true;
    this.router.navigate(['/administration' + item.url]);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  navigetAccessUrl(item: any) {
    this.menuItems.forEach((item: any) => {
      item.isActive = false;
      if (item.submenuItem) {
        item.submenuItem.forEach((obj: any) => (obj.isActive = false));
      }
    });

    item.isActive = true;
    this.router.navigate(['/accessControl' + item.url]);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  toggleCurrentItem(item: any) {
    this.menuItems.forEach((item: any) => {
      item.isActive = false;
      if (item.submenuItem) {
        item.submenuItem.forEach((obj: any) => (obj.isActive = false));
      }
    });
    item.isActive = true;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  storeCurrentCategory(item: any) {
    this.appService.storeCategoryId(item.Id);
    this.appService.storeCategoryName(item.Name);
  }

  logout() {
    return this.authService.logout();
  }

  async getInfoBlockData() {
    this.infoblockService.getInfoblocks().subscribe((res) => {
      this.appService.storeInfoBlockdata(res);
      return Promise.resolve(true);
    });
  }

  async getCountries() {
    this.countryService.getCountries().subscribe((res) => {
      this.appService.storeCountries(res);
    });
  }
}

export interface menuItems {
  Id: string;
  Name: string;
  Title: string;
  Leftinfoblock_Ids: string;
  Rightinfoblock_Ids: string;
  DisplayOrder: string;
  FieldconfigDataJson: any;
  CatGroup: string;
  url: string;
  image: string;
  description: string;
  isActive: boolean;
  submenuItem: [
    {
      tiltle: string;
      url: string;
      description: string;
      isActive: boolean;
      flag: string;
      image: string;
    }
  ];
}
