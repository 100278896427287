import {
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { CompanyService } from '../../../services/company.service';
import { AppService } from '../../../app.service';
import { SubjectService } from '../../../services/subject.service';

@Component({
  selector: 'app-product-services',
  standalone: false,
  templateUrl: './product-services.component.html',
  styleUrl: './product-services.component.css',
})
export class ProductServicesComponent implements OnChanges, OnInit {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() companyId?: string;
  @Input() infoBlockId: string = '';
  @Input() dealerType?: string = '';
  dealerProductAndServices: any;
  originalProductServices: any;
  productAndServices: any = [];
  dealerWorkshopTypeCode: string = '';
  constructor(
    public utilService: UtilsService,
    public CompanyService: CompanyService,
    private appService: AppService,
    private subjectService: SubjectService
  ) {}
  productAndServicesForm =
    inject(CompanyFormService).form.controls.productAndServicesForm;

  ngOnInit(): void {
    this.subjectService.dealerTypeCompanySuject.subscribe((data: string) => {
      if (
        data &&
        this.originalProductServices &&
        this.originalProductServices.length &&
        this.dealerProductAndServices &&
        this.dealerProductAndServices.length
      ) {
        this.dealerWorkshopTypeCode = data;
        this.dealerProductAndServices = this.originalProductServices.filter(
          (item: any) => {
            return item.ShowfordealerworkshoptypesCSV.toLowerCase().includes(
              this.dealerWorkshopTypeCode.toLowerCase()
            );
          }
        );
        this.dealerProductAndServices = this.dealerProductAndServices.sort(
          (a: any, b: any) => a?.Childnum - b?.Childnum
        );
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.CompanyService.getProductAndServices().subscribe((data: any) => {
      this.dealerProductAndServices = data;
      this.originalProductServices = Object.assign(
        [],
        this.dealerProductAndServices
      );
      if (this.dealerWorkshopTypeCode)
        this.dealerProductAndServices = this.originalProductServices.filter(
          (item: any) => {
            return item.ShowfordealerworkshoptypesCSV.toLowerCase().includes(
              this.dealerWorkshopTypeCode.toLowerCase()
            );
          }
        );
      this.dealerProductAndServices = this.dealerProductAndServices.sort(
        (a: any, b: any) => a?.Childnum - b?.Childnum
      );
    });
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.productAndServices = [...this.companyData.CompanySupportedServices];
      this.dealerWorkshopTypeCode =
        this.companyData?.CompanyDealerWorkshop?.DealerWorkshopType_code;
    } else {
      this.productAndServicesForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      if (!this.companyData.CompanySupportedServices) {
        this.companyData.CompanySupportedServices = [];
      }
    }
  }

  handleChecked(id: any) {
    let serviceId = this.productAndServices.find(
      (item: any) => item.DealerWorkshopServices_id === id
    )?.DealerWorkshopServices_id;
    if (serviceId) {
      this.productAndServices = this.productAndServices.filter(
        (item: any) => item.DealerWorkshopServices_id !== serviceId
      );
      this.productAndServicesForm.markAsDirty();
    } else {
      if (this.mode === 'Add') {
        this.productAndServices.push({
          DealerWorkshopServices_id: id,
        });
      } else {
        this.productAndServices.push({
          DealerWorkshopServices_id: id,
          Company_id: this.companyData.myId,
        });
      }
    }
    this.productAndServicesForm.markAsDirty();

    this.saveDraft();
  }

  isavilabel(id: any) {
    let value = this.companyData.CompanySupportedServices.find(
      (i: any) => i.DealerWorkshopServices_id === id
    );
    if (value === undefined || '') {
      return false;
    }
    return true;
  }

  saveDraft() {
    if (this.productAndServicesForm.dirty) {
      //this.companyData.CompanySupportedServices.IsUpdated = true;
      this.companyData.CompanySupportedServices.forEach(
        (item: any) => (item.IsUpdated = true)
      );
    }
    if (this.mode !== 'Add') {
      this.companyData.CompanySupportedServices = [...this.productAndServices];
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.companyData.CompanySupportedServices = [...this.productAndServices];
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}
