import { ChangeDetectorRef, Component, inject, Input, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { AppService } from '../../../app.service';
import { CompanyFormService } from '../../../company/company-form.service';
import { CompanyService } from '../../../services/company.service';
import { UtilsService } from '../../utils.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-social-media-type',
  templateUrl: './social-media-type.component.html',
  styleUrl: './social-media-type.component.css'
})
export class SocialMediaTypeComponent {
  @Input() mode: string = '';
  @Input() companyData: any = {};
  @Input() myId: any = {};
  @Input() infoBlockId:string ='';
  inputForm = new FormGroup({
    Id: new FormControl(''),
    Socialmediatype_code: new FormControl(''),
    PhoneNumber: new FormControl(''),
    Comment: new FormControl(''),
    Company_id: new FormControl(''),
  });
  action ='';
  @ViewChild('AddNewSocialMediaTypeModal', { static: true }) AddNewSocialMediaTypeModal: any;
  @ViewChild('tegeldropDown', { static: true }) tegeldropDown: any;

  socialMediaTypes: any = [];
  socialMediaContacts: any = [];

  CompanySocialMediaContactsForm =
    inject(CompanyFormService).form.controls.CompanySocialMediaContacts;

  constructor(
    public utilService: UtilsService,
    public appService: AppService,
    private companyService: CompanyService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.companyService.getSocialmediatype().subscribe((res: any) => {
      if (res) {
        this.socialMediaContacts = res;
        if (changes['companyData'].currentValue && this.mode !== 'Add') {
          this.companyData = changes['companyData'].currentValue;
  
        } else {
          this.CompanySocialMediaContactsForm.reset();
          this.companyData = this.appService.fetchCompanyAddDraft();
        }
        if(this.companyData.CompanySocialMediaType){
          this.socialMediaTypes = [
            ...this.companyData.CompanySocialMediaType.sort((a:any, b:any) => a.Socialmediatype_code.localeCompare(b.Socialmediatype_code))
          ];
        }
        else{
          this.companyData.CompanySocialMediaType =[];
        }
      }
    });
  }


  AddNewSocialMediaType() {
    this.inputForm.reset();
    this.inputForm.patchValue({
      Id: uuid.v4(),
      Socialmediatype_code: '',
      PhoneNumber: '',
      Comment: '',
      Company_id: this.companyData.Id || this.myId,
    });
    this.AddNewSocialMediaTypeModal.showModal();
    this.action = 'Add'
    this.tegeldropDown.setValue(this.socialMediaContacts[0]?.Code);
  }

  handleDropDown(event: any) {
    this.inputForm.controls.Socialmediatype_code.setValue(
      event.detail.value
    );
  }
  cancelForm() {
    this.AddNewSocialMediaTypeModal.closeModal();
  }

  editFunc(info: any) {
    this.action = 'Edit'
    this.inputForm.reset();
    this.inputForm.patchValue({
      Id: info.Id,
      Socialmediatype_code: info.Socialmediatype_code,
      PhoneNumber: info.PhoneNumber,
      Comment: info.Comment,
      Company_id: this.companyData.Id || this.myId,
    });
    this.AddNewSocialMediaTypeModal.showModal();

    this.tegeldropDown.setValue(info.Socialmediatype_code);
  }

  delFunc(i: number) {
    this.socialMediaTypes.splice(i, 1);
    this.inputForm.markAsDirty();
    this.saveDraft();
  }

  save() {
    this.inputForm.controls.Company_id.setValue(this.companyData.Id);

    if (this.inputForm.value.Id === '' || this.inputForm.value.Id === null) {
      this.inputForm.controls.Id.setValue(uuid.v4());
    }

    if (
      this.inputForm.value.Socialmediatype_code === '' ||
      this.inputForm.value.Socialmediatype_code === null
    ) {
      this.inputForm.controls.Socialmediatype_code.setValue(
        this.socialMediaContacts[0].Code
      );
    }

    if (this.inputForm.valid) {
      let index = this.socialMediaTypes.findIndex(
        (item: any) => item.Id === this.inputForm.value.Id
      );
      if (index !== -1) {
        this.socialMediaTypes[index] = this.inputForm.value;
      } else {

        this.socialMediaTypes.push(this.inputForm.value);
      }

      this.inputForm.markAsDirty();

      this.saveDraft();

      this.inputForm.reset();
      this.socialMediaTypes = this.socialMediaTypes.sort((a:any, b:any) => a.Socialmediatype_code.localeCompare(b.Socialmediatype_code))
      this.AddNewSocialMediaTypeModal.closeModal();
    }
  }

  saveDraft() {
    this.companyData.CompanySocialMediaType = [
      ...this.socialMediaTypes,
    ];
    if (this.inputForm.dirty) {
      this.companyData.CompanySocialMediaType.forEach(
        (item: any) => (item.IsUpdated = true)
      );
    }
    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}