import { Injectable } from '@angular/core';
import { SubjectService } from '../services/subject.service';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    private subjectService: SubjectService,
    private sharedService: SharedService
  ) {}

  getErrorMsgs(
    control: any,
    fieldName: string,
    focus: boolean = false,
    minLength: number = 3,
    message: string = ''
  ) {
    if ((control?.invalid && control?.dirty) || control?.touched || focus) {
      let val = '';
      if (control?.hasError('required')) {
        val = `${fieldName} is required.`;
      }

      if (control?.hasError('minlength')) {
        val = `${fieldName} must be at least ${minLength} characters long..`;
      }

      if (control?.hasError('exists')) {
        val = `${fieldName} already exists.`;
      }

      if (control?.hasError('pattern')) {
        val = message;
      }
      if (control?.hasError('email')) {
        val = message;
      }

      return val;
    } else return '';
  }

  incrementBinaryStr(numStr: string) {
    let num = parseInt(numStr);

    num += 1;

    let incrementBinaryStr = num.toString();

    incrementBinaryStr = incrementBinaryStr.padStart(numStr.length, '0');
    return incrementBinaryStr;
  }

  isObjectEmpty(obj: any) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  alert(
    msg: string,
    subMessage: string = 'Your changes have been saved',
    mode: string = 'success'
  ) {
    this.subjectService.emitToastMessage({
      message: msg,
      mode: mode,
      subMessage: subMessage,
    });
  }

  get ADD_MSG() {
    return this.sharedService.ADD_MSG;
  }

  get UPDATE_MSG() {
    return this.sharedService.UPDATE_MSG;
  }

  get DELETE_MSG() {
    return this.sharedService.DELETE_MSG;
  }

  get COMPANY_ADD_MSG() {
    return this.sharedService.COMPANY_ADD_MSG;
  }

  get COMPANY_UPDATE_MSG() {
    return this.sharedService.COMPANY_UPDATE_MSG;
  }

  get CAPTIVE_DEALER_MSG() {
    return this.sharedService.CAPTIVE_DEALER_MSG;
  }
  get CAPTIVE_DIST_MSG() {
    return this.sharedService.CAPTIVE_DIST_MSG;
  }
  get NON_CAPTIVE_DEALER_MSG() {
    return this.sharedService.NON_CAPTIVE_DEALER_MSG;
  }
  get NON_CAPTIVE_DIST_MSG() {
    return this.sharedService.NON_CAPTIVE_DIST_MSG;
  }
}
