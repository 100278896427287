import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { AppService } from '../app.service';
import { InfoblockService } from '../services/infoblock.service';
import { PhoneTypeService } from '../services/phone-type.service';
import { AddressTypeService } from '../services/address-type.service';
import { ContactTypeService } from '../services/contact-type.service';
import { DashboardService } from '../services/dashboard.service';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { DashboadCard } from '../models/dashboard.model';
import { CountryService } from '../services/country.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css',

  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  cards: DashboadCard[] = [];
  objectKeys = Object.keys;
  countryList: any[] = [];

  constructor(
    private router: Router,
    private infoblockService: InfoblockService,
    private appService: AppService,
    private phoneTypeService: PhoneTypeService,
    private addressTypeService: AddressTypeService,
    private contactTypeService: ContactTypeService,
    private dashboardService: DashboardService,
    private countryService: CountryService
  ) {}
  ngOnInit(): void {
    this.infoblockService.getInfoblocks().subscribe((res) => {
      this.appService.storeInfoBlockdata(res);
    });
    this.phoneTypeService.getPhoneTypes().subscribe((res: any) => {
      if (res) {
        this.appService.formViewModelForPhoneTypes(res);
      }
    });
    this.addressTypeService.getAddressTypes().subscribe((res: any) => {
      if (res) {
        this.appService.formViewModelForAddressTypes(res);
      }
    });
    this.contactTypeService.getContactTypeList().subscribe((res: any) => {
      if (res) {
        this.appService.formViewModelForContactTypes(res);
      }
    });

    this.dashboardService.getCompanyCounts().subscribe((data: any) => {
      this.cards = data;
      setTimeout(() => {
        document.querySelectorAll('tds-card').forEach((ele) => {
          if (ele.shadowRoot) {
            const style = document.createElement('style');
            style.textContent = `.card {
            overflow:visible !important;
          } .card-header {
            display:block !important
           }`;
            ele.shadowRoot.appendChild(style);
          }
        });
      }, 1500);
    });

    this.countryService.getCountries().subscribe((data: any) => {
      this.countryList = data.sort((a: any, b: any) =>
        a.Name.localeCompare(b.Name)
      );
    });
  }

  get IsScaniaAssistanceCoordinator()
  {
    return this.appService.User?.IsScaniaAssistanceCoordinator()
  }
  ngAfterViewInit(): void {}

  handleCardCountryChange(event: any, card: any) {
    card.SelectedCountry = this.cards
      .find((x) => x.Category.Id == card.Category.Id)
      ?.CountryDetails.find((x) => x.Code == event.detail.value);

    if (!card.SelectedCountry && event.detail.value != '-1') {
      card.SelectedCountry = { Name: "N/A", Companies: [] };
    }
  }

  handleGlobalCountryChange(event: any) {
    if (this.cards) {
      this.cards.forEach((card: any) => {
        this.handleCardCountryChange(event, card);
      });
    }
  }

  get isAdmin() {
    return this.appService.User?.IsAdmin();
  }

  getNonCertifiedTotal(card: any) {
    if (card.SelectedCountry)
      return this.cards
        .find((x) => x.Category.Id)
        ?.NonCertifiedCompaniesCount.find(
          (x) => x.Code == card.SelectedCountry.Code
        )?.Companies.length;
    return this.cards
      .find((x) => x.Category.Id)
      ?.NonCertifiedCompaniesCount.map((x) => x.Companies.length)
      .reduce((prev, curr) => prev + curr, 0);
  }

  navigate(event: any, card: any) {
    console.log(event);
    this.router.navigate(['/company/' + card.Category.Name]);
  }
}
