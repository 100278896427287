import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  inject,
  Input,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AppService } from '../../../app.service';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { CompanyService } from '../../../services/company.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as uuid from 'uuid';

@Component({
  selector: 'app-contact-information',
  standalone: false,
  templateUrl: './contact-information.component.html',
  styleUrl: './contact-information.component.css',
})
export class ContactInformationComponent {
  @Input() mode: string = '';
  @Input() companyData: any = {};
  @Input() myId: any = {};
  @Input() infoBlockId: string = '';
  PhoneFocus: boolean = false;
  EmailFocus: boolean = false;
  inputForm = new FormGroup({
    Id: new FormControl(''),
    Globalmanagementtype_code: new FormControl(''),
    Name: new FormControl(''),
    Email: new FormControl('', Validators.email),
    Phoneno: new FormControl('', Validators.pattern(/^\+[1-9][0-9]{9,14}$/)),
    Others: new FormControl(''),
    Company_id: new FormControl(this.myId),
    Mobile: new FormControl('', Validators.pattern(/^\+[1-9][0-9]{9,14}$/)),
    Profile: new FormControl(''),
    Fax: new FormControl(''),
  });
  action = '';
  @ViewChild('AddNewContactModal', { static: true }) AddNewContactModal: any;
  @ViewChild('tegeldropDown', { static: true }) tegeldropDown: any;

  contactInformationDetails: any = [];
  globalMgmtTypeCode: any = [];
  dropDownValue: any;

  CompanyGlobalMangementConatctsForm =
    inject(CompanyFormService).form.controls.CompanyGlobalMangementConatctsForm;

  constructor(
    public utilService: UtilsService,
    public appService: AppService,
    private companyService: CompanyService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.companyService.getGlobalMgmtTypeCode().subscribe((res: any) => {
      if (res) {
        this.globalMgmtTypeCode = res;
        this.globalMgmtTypeCode = this.globalMgmtTypeCode.sort(
          (a: any, b: any) => a?.Displayorder?.localeCompare(b?.Displayorder)
        );
        this.dropDownValue = this.globalMgmtTypeCode.find(
          (item: any) => item.Code === 'Distributors'
        )?.Code;
        if (changes['companyData'].currentValue && this.mode !== 'Add') {
          this.companyData = changes['companyData'].currentValue;
          this.contactInformationDetails = [
            ...this.companyData.CompanyGlobalManagementContacts.sort(
              (a: any, b: any) =>
                a.Globalmanagementtype_code.localeCompare(
                  b.Globalmanagementtype_code
                )
            ),
          ];
          // if (!this.companyData.CompanyGlobalManagementContacts.length) {
          //   // this.setFormValue();
          // } else {
          //  this.getTypeCode().clear();
          // this.companyData.CompanyGlobalManagementContacts.forEach(
          //   (item: any) => {
          //     const globalContactGroup = new FormGroup({
          //       Globalmanagementtype_code: new FormControl(
          //         item.Globalmanagementtype_code
          //       ),
          //       Name: new FormControl(item.Name),
          //       Email: new FormControl(item.Email, Validators.email),
          //       Phoneno: new FormControl(
          //         item.Phoneno,
          //         Validators.pattern('^[- +()0-9]+$')
          //       ),
          //       Id: new FormControl(uuid.v4()),
          //       Others: new FormControl(item.Others),
          //       Company_id: new FormControl(this.companyData.Id),
          //       Mobile: new FormControl(
          //         item.Mobile,
          //         Validators.pattern('^[- +()0-9]+$')
          //       ),
          //       Profile: new FormControl(item.Profile),
          //       Fax: new FormControl(item.Fax),
          //     });
          //     (
          //       this.CompanyGlobalMangementConatctsForm.get(
          //         'CompanyGlobalManagementContacts'
          //       ) as FormArray
          //     ).push(globalContactGroup);
          //   }
          // );
          // }
        } else {
          this.CompanyGlobalMangementConatctsForm.reset();
          // this.setFormValue();
          this.companyData = this.appService.fetchCompanyAddDraft();
        }
      }
    });
  }

  getErrorMsgs(control: any, fieldName: string, focus: boolean = false) {
    if ((control?.invalid && control?.dirty) || control?.touched || focus) {
      let val = '';
      if (control?.hasError('email')) {
        val = `${fieldName} format is not valid - ex test@scania.com.`;
      }

      if (control?.hasError('pattern')) {
        val =
          '(I) No spaces, parenthesis or other punctuation allowed. (II) Number should not have leading zero. (III) Country calling code should not be missing. (IV) Number must start with a ”+”. for Ex : +263665689880';
      }
      return val;
    } else return '';
  }

  getEmail(controlName: string): FormControl {
    return this.inputForm.get(controlName) as FormControl;
  }

  getPhone(controlName: string): FormControl {
    return this.inputForm.get(controlName) as FormControl;
  }

  getMobile(controlName: string): FormControl {
    return this.inputForm.get(controlName) as FormControl;
  }

  getPositionName(code: any): string {
    return (
      this.globalMgmtTypeCode.find((item: any) => item.Code === code)?.Name ||
      ''
    );
  }

  setFormValue() {
    // this.getTypeCode().clear();
    const CompanyGlobalManagementContactGroup = new FormGroup({
      Globalmanagementtype_code: new FormControl(
        this.globalMgmtTypeCode.find(
          (item: any) => item.Code === 'Distributors'
        )?.Code
      ),
      Id: new FormControl(uuid.v4()),
      Name: new FormControl(''),
      Email: new FormControl('', Validators.email),
      Phoneno: new FormControl('', Validators.pattern('^[- +()0-9]+$')),
      Others: new FormControl(''),
      Company_id: new FormControl(this.myId),
      Mobile: new FormControl('', Validators.pattern('^[- +()0-9]+$')),
      Profile: new FormControl(''),
      Fax: new FormControl(''),
    });
    (
      this.CompanyGlobalMangementConatctsForm.get(
        'CompanyGlobalManagementContacts'
      ) as FormArray
    ).push(CompanyGlobalManagementContactGroup);
  }

  AddNewContact() {
    this.inputForm.reset();
    // this.CompanyGlobalMangementConatctsForm.reset();
    this.inputForm.patchValue({
      Id: '',
      Globalmanagementtype_code: '',
      Name: '',
      Email: '',
      Phoneno: '',
      Others: '',
      Company_id: '',
      Mobile: '',
      Profile: '',
      Fax: '',
    });
    this.AddNewContactModal.showModal();
    this.action = 'Add';
    const defaultValue = this.globalMgmtTypeCode.find(
      (item: any) => item.Code === 'Distributors'
    )?.Code;
    this.tegeldropDown.setValue(defaultValue);
  }

  handleAccessDropDown(event: any) {
    this.inputForm.controls.Globalmanagementtype_code.setValue(
      event.detail.value
    );
  }

  // getTypeCode(): FormArray {
  //   return this.CompanyGlobalMangementConatctsForm.get(
  //     'CompanyGlobalManagementContacts'
  //   ) as FormArray;
  // }

  cancelForm() {
    this.AddNewContactModal.closeModal();
  }

  editFunc(info: any) {
    this.action = 'Edit';
    this.inputForm.reset();
    this.dropDownValue = info.Globalmanagementtype_code;
    this.inputForm.patchValue({
      Globalmanagementtype_code: info.Globalmanagementtype_code,
      Name: info.Name?.trim(),
      Email: info.Email?.trim(),
      Phoneno: info.Phoneno?.trim(),
      Others: info.Others?.trim(),
      Company_id: this.companyData.Id?.trim(),
      Mobile: info.Mobile?.trim(),
      Profile: info.Profile?.trim(),
      Fax: info.Fax?.trim(),
      Id: info.Id?.trim(),
    });
    this.AddNewContactModal.showModal();

    this.inputForm.controls.Globalmanagementtype_code.patchValue(
      info.Globalmanagementtype_code
    );

    this.tegeldropDown.setValue(info.Globalmanagementtype_code);
    this.EmailFocus = true;
    this.PhoneFocus = true;
  }

  delFunc(i: number) {
    this.contactInformationDetails.splice(i, 1);
    this.inputForm.markAsDirty();
    this.saveDraft();
  }

  saveContact() {
    this.PhoneFocus = true;
    this.EmailFocus = true;
    this.inputForm.controls.Company_id.setValue(this.companyData.Id);

    if (this.inputForm.value.Id === '' || this.inputForm.value.Id === null) {
      this.inputForm.controls.Id.setValue(uuid.v4());
    }

    if (
      this.inputForm.value.Globalmanagementtype_code === '' ||
      this.inputForm.value.Globalmanagementtype_code === null
    ) {
      this.inputForm.controls.Globalmanagementtype_code.setValue(
        this.globalMgmtTypeCode[0]?.Code
      );
    }

    if (this.inputForm.valid) {
      let index = this.contactInformationDetails.findIndex(
        (item: any) => item.Id === this.inputForm.value.Id
      );
      if (index !== -1) {
        this.contactInformationDetails[index] = this.inputForm.value;
      } else {
        // this.getTypeCode().push(
        //   new FormGroup({
        //     Globalmanagementtype_code: new FormControl(
        //       this.inputForm.get('Globalmanagementtype_code')?.value
        //     ),
        //     Name: new FormControl(this.inputForm.get('Name')?.value),
        //     Email: new FormControl(
        //       this.inputForm.get('Email')?.value,
        //       Validators.email
        //     ),
        //     Phoneno: new FormControl(
        //       this.inputForm.get('Phoneno')?.value,
        //       Validators.pattern('^[- +()0-9]+$')
        //     ),
        //     Others: new FormControl(this.inputForm.get('Others')?.value),
        //     Company_id: new FormControl(this.companyData.Id),
        //     Mobile: new FormControl(
        //       this.inputForm.get('Mobile')?.value,
        //       Validators.pattern('^[- +()0-9]+$')
        //     ),
        //     Profile: new FormControl(this.inputForm.get('Profile')?.value),
        //     Fax: new FormControl(this.inputForm.get('Fax')?.value),
        //   })
        // );
        this.contactInformationDetails.push(this.inputForm.value);
      }

      this.inputForm.markAsDirty();

      this.saveDraft();

      this.inputForm.reset();
      this.contactInformationDetails = this.contactInformationDetails.sort(
        (a: any, b: any) =>
          a.Globalmanagementtype_code.localeCompare(b.Globalmanagementtype_code)
      );
      this.AddNewContactModal.closeModal();
    }
  }

  saveDraft() {
    if (this.inputForm.dirty) {
      //  this.companyData.CompanyGlobalManagementContacts.IsUpdated = true;
      this.companyData.CompanyGlobalManagementContacts.forEach(
        (item: any) => (item.IsUpdated = true)
      );
    }
    this.companyData.CompanyGlobalManagementContacts = [
      ...this.contactInformationDetails,
    ];

    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}
