import {
  Component,
  inject,
  Input,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AppService } from '../../../app.service';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { CompanyService } from '../../../services/company.service';
import { Validators } from '@angular/forms';
import { SubjectService } from '../../../services/subject.service';

@Component({
  selector: 'app-scania-dealer-operating-standard',
  templateUrl: './scania-dealer-operating-standard.component.html',
  styleUrl: './scania-dealer-operating-standard.component.css',
})
export class ScaniaDealerOperatingStandardComponent {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any = {};
  @Input() infoBlockId: string = '';
  companyDOSCertificationForm =
    inject(CompanyFormService).form.controls.CompanyDOSCertification;
  companyDealerWorkshopForm =
    inject(CompanyFormService).form.controls.CompanyDealerWorkshop;
  dosStatus = false;
  applicant = false;
  headerText: string = '';
  popUpMessage: string = '';
  @ViewChild('historyModal', { static: true }) historyModal: any;
  @ViewChild('InfoModal', { static: true }) popUpModal: any;
  history: any = [];
  availableCommentFocus: boolean = false;
  minDate = new Date();
  constructor(
    public utilService: UtilsService,
    public appService: AppService,
    private companyService: CompanyService,
    private subjectService: SubjectService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.companyDOSCertificationForm.patchValue({
        IsCertified: this.companyData?.CompanyDOSCertification?.IsCertified,
        Evaluation: this.companyData?.CompanyDOSCertification?.Evaluation,
        Company_id: this.companyData?.CompanyDOSCertification?.Company_id,
        ExcemptionUntilComment:
          this.companyData?.CompanyDOSCertification?.ExcemptionUntilComment,
        ExcemptionuntilOverride:
          this.companyData?.CompanyDOSCertification?.ExcemptionuntilOverride,
        AvailableforExternalSystems:
          this.companyData?.CompanyDOSCertification
            ?.AvailableforExternalSystems,
        Availableforexternalsystems_comment:
          this.companyData?.CompanyDOSCertification
            ?.Availableforexternalsystems_comment,
        DOSStatus: this.companyData?.CompanyDOSCertification?.DOSStatus,
        Expiringdate: this.companyData?.CompanyDOSCertification?.Expiringdate,
      });
      if (!this.companyDOSCertificationForm.value.AvailableforExternalSystems) {
        this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.disable();
        this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.clearValidators();
      } else {
        this.availableCommentFocus = true;
        this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.enable();
        this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.setValidators(
          Validators.required
        );
      }

      this.dosStatus = this.companyData?.CompanyDOSCertification?.DOSStatus;
      this.applicant = this.companyData?.CompanyDOSCertification?.Evaluation;
    } else {
      this.companyDOSCertificationForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      if (this.companyData.CompanyDOSCertification) {
        this.companyDOSCertificationForm.patchValue({
          IsCertified: this.companyData?.CompanyDOSCertification?.IsCertified,
          Evaluation: this.companyData?.CompanyDOSCertification?.Evaluation,
          Company_id: this.companyData?.CompanyDOSCertification?.Company_id,
          ExcemptionUntilComment:
            this.companyData?.CompanyDOSCertification?.ExcemptionUntilComment,
          ExcemptionuntilOverride:
            this.companyData?.CompanyDOSCertification?.ExcemptionuntilOverride,
          AvailableforExternalSystems:
            this.companyData?.CompanyDOSCertification
              ?.AvailableforExternalSystems,
          Availableforexternalsystems_comment:
            this.companyData?.CompanyDOSCertification
              ?.Availableforexternalsystems_comment,
          DOSStatus: this.companyData?.CompanyDOSCertification?.DOSStatus,
          Expiringdate: this.companyData?.CompanyDOSCertification?.Expiringdate,
        });
        if (
          !this.companyDOSCertificationForm.value.AvailableforExternalSystems
        ) {
          this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.disable();
        }
      } else {
        this.companyData.CompanyDOSCertification = {};
        this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.disable();
      }
      this.saveDraft();
      this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.clearValidators();
    }

    this.subjectService.validationCompanySubject.subscribe((data) => {
      if (data) {
        this.availableCommentFocus = true;
      }
    });
  }

  handleExternalSystems(event: any) {
    this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.clearValidators();
    if (event.detail.checked) {
      this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.addValidators(
        Validators.required
      );
      this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.enable();
      this.companyDOSCertificationForm.controls.DOSStatus.setValue(false);
      this.companyDOSCertificationForm.controls.AvailableforExternalSystems.setValue(
        true
      );

      let dealerId = this.companyDealerWorkshopForm.controls.DealerId.value;
      this.headerText = 'Available for External System';
      this.popUpMessage = `The checkbox "Available for External Systems" has been checked meaning that dealer ID ${dealerId} will not be visible for External Systems`;
      this.popUpModal.showModal();
    } else {
      this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.clearValidators();
      this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.disable();
      this.companyDOSCertificationForm.controls.Availableforexternalsystems_comment.setValue(
        ''
      );
      this.companyDOSCertificationForm.controls.AvailableforExternalSystems.setValue(
        false
      );
      if (this.mode !== 'Add' && !this.applicant) {
        this.companyDOSCertificationForm.controls.DOSStatus.setValue(true);
      }
    }
    this.companyDOSCertificationForm.markAsDirty();
  }

  getLabelText(labelId: string, infoBlockId: string) {
    return this.appService.getLabelText(labelId, infoBlockId);
  }

  handleApplicant(event: any) {
    if (event.detail.checked) {
      this.applicant = true;
      if (this.mode !== 'Add' && !this.DOSStatus) {
        this.companyDOSCertificationForm.controls.DOSStatus.setValue(false);
      }
      let dealerId = this.companyDealerWorkshopForm.controls.DealerId.value;
      this.headerText = 'Applicant';
      this.popUpMessage = `The checkbox Applicant has been checked meaning that dealer ID ${dealerId} will only be visible for QPR web and not communicated to any other systems`;
      this.popUpModal.showModal();
    } else {
      this.applicant = false;
      if (
        this.mode !== 'Add' &&
        this.DOSStatus &&
        !this.companyDOSCertificationForm.value?.AvailableforExternalSystems
      ) {
        this.companyDOSCertificationForm.controls.DOSStatus.setValue(true);
      }
    }
    this.companyDOSCertificationForm.markAsDirty();
  }

  get ExcemptionuntilOverride() {
    return this.companyDOSCertificationForm.controls.ExcemptionuntilOverride;
  }

  handleOverrideDatePicker(event: any) {
    if (event.target.value) {
      const date = new Date(event.target.value);
      this.companyDOSCertificationForm.controls.ExcemptionuntilOverride.setValue(
        event.target.value + 'T00:00:00'
      );
      this.companyDOSCertificationForm.markAsDirty();
      this.minDate.setHours(date.getHours(),date.getMinutes(),date.getSeconds(),date.getMilliseconds());
      if (date < this.minDate || date.getFullYear().toString().length !== 4) {
        this.companyDOSCertificationForm.controls.ExcemptionuntilOverride.setErrors(
          { invalid: true }
        );
      } else {
        this.companyDOSCertificationForm.controls.ExcemptionuntilOverride.setErrors(
          null
        );
      }
    }
  }

  ViewHistory() {
    this.history = [];
    this.companyService
      .GetHistory(this.companyData.Id)
      .subscribe((res: any) => {
        if (res) {
          this.history = [];
          res.forEach((element: any) => {
            if (
              element.Typ.includes('DOSExcemptionOverride') ||
              element.Typ.includes('DOSExcemptionOverrideCleared') ||
              element.Typ.includes('AvailibilityforExternalSystemModified')
            ) {
              element.Data = JSON.parse(element.Data);
              this.history.push(element);
            }
          });
          this.historyModal.showModal();
        }
      });
  }

  saveDraft() {
    if (!this.companyData.CompanyDOSCertification)
      this.companyData.CompanyDOSCertification = {};

    this.companyData.CompanyDOSCertification.IsCertified =
      this.companyDOSCertificationForm.get('IsCertified')?.value || false;
    this.companyData.CompanyDOSCertification.Evaluation = this.applicant;
    this.companyData.CompanyDOSCertification.ExcemptionUntilComment =
      this.companyDOSCertificationForm.get('ExcemptionUntilComment')?.value;
    this.companyData.CompanyDOSCertification.ExcemptionuntilOverride =
      this.companyDOSCertificationForm.get('ExcemptionuntilOverride')?.value;
    this.companyData.CompanyDOSCertification.AvailableforExternalSystems =
      this.companyDOSCertificationForm.get(
        'AvailableforExternalSystems'
      )?.value;
    this.companyData.CompanyDOSCertification.Availableforexternalsystems_comment =
      this.companyDOSCertificationForm.get(
        'Availableforexternalsystems_comment'
      )?.value;
    this.companyData.CompanyDOSCertification.DOSStatus =
      this.companyDOSCertificationForm.get('DOSStatus')?.value;

    if (this.companyDOSCertificationForm.dirty) {
      this.companyData.CompanyDOSCertification.IsUpdated = true;
    }
    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }

  get Availableforexternalsystems_comment() {
    return this.companyDOSCertificationForm.get(
      'Availableforexternalsystems_comment'
    );
  }

  get DOSStatus(): boolean {
    if (
      this.companyData.CompanyDOSCertification?.Evaluation ||
      this.companyDOSCertificationForm.value?.AvailableforExternalSystems
    ) {
      return false;
    } else if (
      this.parseDate(
        this.companyDOSCertificationForm.get('ExcemptionuntilOverride')
          ?.value || this.defaultDate()
      ) >= new Date()
    ) {
      return true;
    } else if (
      this.parseDate(
        this.companyDOSCertificationForm.get('ExcemptionuntilOverride')
          ?.value || this.defaultDate()
      ) < new Date()
    ) {
      return false;
    }
    return false;
  }

  parseDate(dateString: string) {
    return new Date(dateString);
  }

  defaultDate() {
    return new Date(new Date().setDate(new Date().getDate() - 1))
      .toISOString()
      .split('T')[0];
  }
}
