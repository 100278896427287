<div class="flex">
  <div class="h-52 w-full grid justify-items-center mt-3">
    <p
      class="font-bold text-3xl inline-block align-text-bottom mt-5"
      id="heading"
    >
      Access Denied
    </p>
    <p class="tds-detail-04 tds-text-blue-500"></p>

    <tds-message variant="error" header="Error">
      <div>
        
        <p>
          You have tried to reach the Scania International Service (SIS)
          database. Read-only information is available in SIS Internal (
          <a target="_blank" href="{{ internalUrl }}">{{ internalUrl }} </a>)
        </p>
        <p>
          If information in the SIS database needs to be updated, please contact
          the local SIS coordinator (information found in SIS
          Internal/Distributors).
        </p>
        <p>
          If you need access to the SIS database (only for DOS and SIS
          coordinators), please contact
          <a href="mailto:johan.larsson@scania.com"
            >johan.larsson&#64;scania.com</a
          >
          to apply for access.
        </p>

        <h5>
          Your email address : {{ email }}
        </h5>
      </div>
    </tds-message>
  </div>
</div>
