import { Injectable } from '@angular/core';
import {
  OAuthService,
  AuthConfig,
  OAuthErrorEvent,
  OAuthSuccessEvent,
} from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { Observable, retry, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';
import { AppService } from '../app.service';
import { Router } from '@angular/router';

export interface IdentityClaims {
  given_name?: string;
  family_name?: string;
  email?: string;
  preferred_username?: string;
}

const authConfig: AuthConfig = {
  issuer: environment.authConfig.oauth.issuer,
  redirectUri: environment.authConfig.oauth.redirectSignIn,
  logoutUrl: '',
  postLogoutRedirectUri: environment.authConfig.oauth.redirectSignOut,
  clientId: environment.authConfig.userPoolWebClientId,
  responseType: environment.authConfig.oauth.responseType,
  scope: environment.authConfig.oauth.scope,
  showDebugInformation: true,
  strictDiscoveryDocumentValidation: false,
  oidc: true,
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authStateSubject = new Subject<boolean>();
  private authStateObservable = this.authStateSubject.asObservable();

  private userDetailsSubject = new Subject<boolean>();
  private userDetailsObservable = this.userDetailsSubject.asObservable();

  private user: User | undefined;
  queryParam: any;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private http: HttpClient,
    private appService: AppService
  ) {
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    //this.oauthService.events.subscribe(e => console.log(e));

    this.oauthService.events.subscribe((event) => {
      //console.error(event);
      if (event instanceof OAuthErrorEvent) {
        console.error(event);
      }

      if (event instanceof OAuthSuccessEvent) {
        if (event.type === 'token_received') {
          this.storeUserDetails();
          if (this.appService.isSESRedirect()) {
            this.router.navigateByUrl(this.appService.getSESRedirect());
          } else {
            this.router.navigateByUrl('/');
          }
          this.authStateSubject.next(this.isAuthenticated);
        } else if (event.type === 'token_refreshed') {
          this.storeUserDetails();
        } else if (
          event.type === 'discovery_document_loaded' &&
          this.isAuthenticated
        ) {
          this.storeUserDetails();
        } else {
          console.warn(event);
        }
      }
    });
  }

  login() {
    this.oauthService.initLoginFlow();
  }

  logout() {
    try {
      //logout from cognito and redirect to /logout
      this.oauthService.logOut(true);
      window.location.href = `${environment.authConfig.oauth.domain}/logout?client_id=${environment.authConfig.userPoolWebClientId}&logout_uri=${environment.authConfig.oauth.redirectSignOut}`;
    } catch (error) {
      console.log(error);
    }
  }

  redirectOnCallback(): void {}

  get User(): User | undefined {
    return this.user;
  }

  storeUserDetails() {
    this.getUserDetails().subscribe((response: any) => {
      if (response.status === 204) {
        // SES User
        if (this.appService.isSESRedirect()) {
          this.router.navigateByUrl(this.appService.getSESRedirect());
        } else {
          //user not found
          this.router.navigate(['/noaccess']);
        }
      } else {
        this.user = Object.assign(new User(), response.body);
        let userCatspec = this.user?.UserAccessType?.CatSpecification
          ? JSON.parse(this.user?.UserAccessType?.CatSpecification)
          : '';
        if (userCatspec && this.user && this.user.UserAccessType.Catspec)
          this.user.UserAccessType.Catspec = userCatspec;

        this.appService.storeUserDetails(this.user);
        this.userDetailsSubject.next(true);
      }
    });
  }

  getUserDetails() {
    if (this.appService.isSESRedirect()) {
      return this.http.get(
        environment.baseUrl +
          '/v1/user/' +
          this.appService.getSESUserFromRedirect(),
        { observe: 'response' }
      );
    } else {
      return this.http.get(
        environment.baseUrl + '/v1/user/filter/' + this.identityClaims?.email,
        { observe: 'response' }
      );
    }
  }

  get identityClaims(): IdentityClaims | null {
    let claims = this.oauthService.getIdentityClaims() as IdentityClaims;
    if (claims && !claims.email) claims.email = claims.preferred_username;
    return claims;
  }

  get isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  isAuthenticatedObservable(): Observable<boolean> {
    return this.authStateObservable;
  }

  isUserDetailsLoadedObservable(): Observable<boolean> {
    return this.userDetailsObservable;
  }

  getAuthorizationToken() {
    return this.oauthService.getAccessToken();
  }
  getIdToken() {
    return this.oauthService.getIdToken();
  }
}
