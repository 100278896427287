import { Component, inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { AppService } from '../../../app.service';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { LocationService } from '../../../services/location.service';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { SubjectService } from '../../../services/subject.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrl: './location.component.css',
})
export class LocationComponent implements OnInit {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any = {};
  @Input() infoBlockId: string = '';
  companyLocationForm =
    inject(CompanyFormService).form.controls.CompanyLocation;
  latitudeFocus = false;
  longitudeFocus = false;
  center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  zoom = 8;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.BLOCK_START_INLINE_END,
    },
  };
  pos = { lat: -34.075023, lng: 18.844073 }; // position of the map and the marker
  markerOptions = { animation: google.maps.Animation.DROP, draggable: true };
  locationRequired: boolean = false;
  originalCenter: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  locationInfoText: string =
    'Maximise the window, drag the Pegman icon (person) to the street, close full screen, and click "use this position", or switch to satellite view, zoom in, place the pin correctly, and click "use this position". Please refer user manual for more details';
  constructor(
    public utilService: UtilsService,
    public appService: AppService,
    private locationService: LocationService,
    public companyFormService: CompanyFormService,
    private subjectService: SubjectService
  ) {}
  ngOnInit(): void {
    this.subjectService.validationCompanySubject.subscribe((data) => {
      if (data) {
        this.latitudeFocus = true;
        this.longitudeFocus = true;
      }
    });
  }

  get Longitude() {
    return this.companyLocationForm.get('Longitude');
  }

  get Latitude() {
    return this.companyLocationForm.get('Latitude');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.companyLocationForm.controls.TimeZoneId.disable();
    this.companyLocationForm.controls.Latitude.addValidators(
      this.formatValidator()
    );
    this.companyLocationForm.controls.Longitude.addValidators(
      this.formatValidator()
    );
    if (this.companyFormService.getLocationFormValidation()) {
      this.locationRequired = true;
      this.companyLocationForm.controls.Latitude.addValidators(
        Validators.required
      );
      this.companyLocationForm.controls.Longitude.addValidators(
        Validators.required
      );
    } else {
      this.locationRequired = false;
      this.companyLocationForm.controls.Latitude.removeValidators(
        Validators.required
      );
      this.companyLocationForm.controls.Longitude.removeValidators(
        Validators.required
      );
    }
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.latitudeFocus = true;
      this.longitudeFocus = true;
      this.companyData = changes['companyData'].currentValue;
      this.companyLocationForm.patchValue({
        Latitude: this.companyData?.CompanyLocation?.Latitude,
        Longitude: this.companyData?.CompanyLocation?.Longitude,
        Company_id: this.companyData?.CompanyLocation?.Company_id,
        TimeZoneId: this.companyData?.CompanyLocation?.TimeZoneId,
      });
      this.center = {
        lat: Number(this.companyData?.CompanyLocation?.Latitude) || 0,
        lng: Number(this.companyData?.CompanyLocation?.Longitude) || 0,
      };
      this.originalCenter = Object.assign({}, this.center);
    } else {
      this.companyLocationForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      if (this.companyData.CompanyLocation) {
        this.companyLocationForm.patchValue({
          Latitude: this.companyData?.CompanyLocation?.Latitude,
          Longitude: this.companyData?.CompanyLocation?.Longitude,
          Company_id: this.companyData?.CompanyLocation?.Company_id,
          TimeZoneId: this.companyData?.CompanyLocation?.TimeZoneId,
        });
        this.center = {
          lat: Number(this.companyData?.CompanyLocation?.Latitude) || 0,
          lng: Number(this.companyData?.CompanyLocation?.Longitude) || 0,
        };
      } else {
        this.companyData.CompanyLocation = {};
      }
    }
  }

  getLocation(event: any): void {
    if (event.latLng) {
      let lat = event.latLng.lat();
      let lng = event.latLng.lng();
      this.center = { lat: Number(lat), lng: Number(lng) };
      this.companyLocationForm.controls.Latitude.setValue(
        this.center.lat.toFixed(6)
      );
      this.companyLocationForm.controls.Longitude.setValue(
        this.center.lng.toFixed(6)
      );
      this.companyLocationForm.markAsDirty();
    }
  }

  setSelectedPosition() {
    this.companyLocationForm.controls.Latitude.setValue(
      this.center.lat.toFixed(6)
    );
    this.companyLocationForm.controls.Longitude.setValue(
      this.center.lng.toFixed(6)
    );
    this.getTimeZone();
    this.originalCenter = Object.assign({}, this.center);
  }

  cancelSelectedPosition() {
    this.center = Object.assign({}, this.originalCenter);
    this.companyLocationForm.controls.Latitude.setValue(
      this.center.lat.toFixed(6)
    );
    this.companyLocationForm.controls.Longitude.setValue(
      this.center.lng.toFixed(6)
    );
    this.getTimeZone();
  }

  getLabelText(labelId: string, infoBlockId: string) {
    return this.appService.getLabelText(labelId, infoBlockId);
  }

  getPopupHelpText(labelId: string, infoBlockId: string) {
    return this.appService.getPopupHelpText(labelId, infoBlockId);
  }

  getTimeZone() {
    this.companyLocationForm.markAsDirty();
    this.locationService
      .getTimeZone(this.Latitude?.value, this.Longitude?.value)
      .subscribe((res: any) => {
        if (res) {
          this.companyLocationForm.controls.TimeZoneId.setValue(res.timeZoneId);
          this.saveDraft();
        }
      });
  }

  formatValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      let valid;
      if (control.value) {
        valid = /^-?\d{1,3}\.\d{6}$/.test(control.value);
      } else {
        valid = true;
        this.companyLocationForm.controls.TimeZoneId.setValue('');
      }
      return valid ? null : { invalidLocationFormat: true };
    };
  }

  getErrorMsgs(control: any, fieldName: string, focus: boolean = false) {
    if ((control?.invalid && control?.dirty) || control?.touched || focus) {
      let val = '';
      if (control?.hasError('required')) {
        return `${fieldName} is required.`;
      }
      if (control?.hasError('invalidLocationFormat')) {
        return "Location format is always 2 or 3 digits followed by '.' then 6 more digits";
      }
      return val;
    } else return '';
  }

  saveDraft() {
    if (!this.companyData.CompanyLocation)
      this.companyData.CompanyLocation = {};
    this.companyData.CompanyLocation.Latitude =
      this.companyLocationForm.get('Latitude')?.value;
    this.companyData.CompanyLocation.Longitude =
      this.companyLocationForm.get('Longitude')?.value;
    this.companyData.CompanyLocation.TimeZoneId =
      this.companyLocationForm.get('TimeZoneId')?.value;
    this.companyData.CompanyLocation.Company_id =
      this.companyLocationForm.get('Company_id')?.value || this.companyData.Id;
    this.center = {
      lat: Number(this.companyData?.CompanyLocation?.Latitude),
      lng: Number(this.companyData?.CompanyLocation?.Longitude),
    };
    if (this.companyLocationForm.dirty) {
      this.companyData.CompanyLocation.IsUpdated = true;
    }
    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}
