import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { addressTypes } from '../adress-types-home/adress-types-home.component';
import { AddressTypeService } from '../../../services/address-type.service';
import { UtilsService } from '../../../common/utils.service';
@Component({
  selector: 'app-add-edit-address-types',
  templateUrl: './add-edit-address-types.component.html',
  styleUrl: './add-edit-address-types.component.css',
})
export class AddEditAddressTypesComponent implements OnChanges {
  categoryAccessData: any = [];

  @Input() addressType: addressTypes = {} as addressTypes;
  @Input() mode: string = '';
  @Input() addressTypes: addressTypes[] = [];
  @Output() setMode = new EventEmitter<string>();
  @Output() updateList = new EventEmitter<addressTypes>();
  @Input() highestDisplayOrder: string = '';

  submitted: boolean = false;
  added: boolean = false;
  codeFocus: boolean = false;
  nameFocus: boolean = false;

  editForm = new FormGroup({
    Code: new FormControl('', [Validators.required, Validators.minLength(3)]),
    Name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    Displayorder: new FormControl(''),
  });

  constructor(
    private utilsService: UtilsService,
    private addressTypeService: AddressTypeService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['addressType']?.previousValue) {
    this.addressType = changes['addressType']?.currentValue;
    // }
    // if (changes['addressTypes']?.previousValue) {
    this.addressTypes =
      changes['addressTypes']?.currentValue || this.addressTypes;
    // }
    this.editForm.controls.Code.addValidators(
      this.codeExists(this.addressTypes)
    );
    this.editForm.controls.Name.addValidators(
      this.nameExists(this.addressTypes)
    );
    this.editForm.setValue({
      Code: this.addressType.Code,
      Name: this.addressType.Name,
      Displayorder: this.addressType.Displayorder,
    });
    if (this.mode === 'Edit') {
      this.editForm.controls.Code.disable();
    } else {
      this.editForm.controls.Code.addValidators(
        this.codeExists(this.addressTypes)
      );
    }
  }

  get Code() {
    return this.editForm.get('Code');
  }

  get Name() {
    return this.editForm.get('Name');
  }

  nameExists(addressList: addressTypes[]) {
    return (control: AbstractControl): ValidationErrors | null => {
      const exists = addressList.find(
        (item) =>
          item.Name.toLocaleLowerCase() ===
            control.value.toLocaleLowerCase().trim() &&
          item.Name !== this.addressType.Name
      );
      return exists ? { exists: { value: control.value } } : null;
    };
  }
  codeExists(addressList: addressTypes[]) {
    return (control: AbstractControl): ValidationErrors | null => {
      const exists = addressList.find(
        (item) =>
          item.Code.toLocaleLowerCase() ===
            control.value.toLocaleLowerCase().trim() &&
          item.Code !== this.addressType.Code
      );
      return exists ? { exists: { value: control.value } } : null;
    };
  }

  updateDetails() {
    this.addressType.Name = this.Name?.value || '';
    this.addressType.Code = this.Code?.value || '';
  }

  onSubmit() {
    this.submitted = true;
    this.codeFocus = true;
    this.nameFocus = true;
    let request = this.editForm.value;
    if (this.editForm.valid) {
      if (this.mode === 'Edit') {
        request.Code = this.editForm.controls.Code.value;
        this.addressTypeService
          .updateAddressData(request)
          .subscribe((res: any) => {
            this.utilsService.alert(this.utilsService.UPDATE_MSG);
            this.updateList.emit(res);
            this.cancel();
            //console.log('Data Edited');
          });
      } else {
        this.editForm.value.Displayorder = this.utilsService.incrementBinaryStr(
          this.highestDisplayOrder
        );
        this.addressTypeService
          .addAddressData(request)
          .subscribe((res: any) => {
            this.utilsService.alert(this.utilsService.ADD_MSG);
            this.updateList.emit(res);
            this.cancel();
            // console.log('Data Added!');
          });
      }
    }
  }

  getErrorMsgs(control: any, fieldName: string, focus: boolean = false) {
    if ((control?.invalid && control?.dirty) || control?.touched || focus) {
      let val = '';
      if (control?.hasError('required')) {
        val = `${fieldName} is required.`;
      }
      if (control?.hasError('minlength')) {
        val = `${fieldName} must be at least 3 characters long.`;
      }
      if (control?.hasError('exists')) {
        val = `${fieldName} already exists.`;
      }
      return val;
    } else return '';
  }

  cancel() {
    this.setMode.emit('');
  }
}
