import { Component, OnInit } from '@angular/core';
import { TegelModule } from '@scania/tegel-angular';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { CompanyService } from '../services/company.service';
import { CategoriesService } from '../services/categories.service';

@Component({
  selector: 'app-no-access',
  standalone: true,
  imports: [TegelModule],
  templateUrl: './ses-home.component.html',
  styleUrl: './ses-home.component.css',
})
export class SESHomeComponent implements OnInit {
  constructor(
    private appService: AppService,
    private companyService: CompanyService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.appService.isSESRedirect()) {
      this.loadCompanyListFromDealerId();
    }
  }

  loadCompanyListFromDealerId() {
    const dealerId = this.appService.getSESDealerIdFromRedirect();
    const country = this.appService.getSESCountryFromRedirect();

    if (!dealerId || !country) {
      this.router.navigateByUrl('/company/DealerWorkshops');
      return;
    }
    if (dealerId && country) {
      this.companyService
        .getCompanyByDealerAndCountry(encodeURIComponent(dealerId), country)
        .subscribe((data: any) => {
          sessionStorage.setItem('ses_company_id', data.Id);

          this.companyService
            .getCategoryDetails(data.Category_Id)
            .subscribe((category: any) => {
              if (category) {
                this.router.navigateByUrl('/company/' + category.Name);
              }
            });
        });
    }
  }
}
