import {
  Component,
  Inject,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { TegelModule } from '@scania/tegel-angular';
import * as uuid from 'uuid';
import { CountryService } from '../../../services/country.service';
import { UtilsService } from '../../utils.service';
import { NavigationEnd, Router } from '@angular/router';
import { CompanyFormService } from '../../../company/company-form.service';
import { AppService } from '../../../app.service';
import {
  CommonModule,
  DatePipe,
  formatDate,
  NgComponentOutlet,
} from '@angular/common';
import { SubjectService } from '../../../services/subject.service';
import { User } from '../../../models/user.model';

@Component({
  selector: 'app-company-information',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TegelModule,
    DatePipe,
    NgComponentOutlet,
  ],
  templateUrl: './company-information.component.html',
  styleUrl: './company-information.component.css',
})
export class CompanyInformationComponent implements OnInit, OnChanges {
  countryList: Country[] = [];
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() infoBlockId: string = '';
  selectedCountry: any;
  catFieldConfig: any;
  user: User | undefined;
  categoryId: string = '';
  constructor(
    private countryService: CountryService,
    public utilService: UtilsService,
    private router: Router,
    public appService: AppService,
    private subjectService: SubjectService
  ) {}

  ngOnInit(): void {
    this.user = this.appService.User;
    this.categoryId = this.appService.selectedCategoryId;
    this.catFieldConfig = this.appService.fetchCatgoryFieldConfig();
    this.countryService.getCountries().subscribe((res: any) => {
      if (res) {
        this.countryList = res.sort((a: any, b: any) =>
          a.Name.localeCompare(b.Name)
        );
        this.countryList = this.countryList.filter((item) => {
          return this.user?.ShowInCountryList(item.Code, this.categoryId);
        });
        this.selectedCountry = this.countryList[0];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.companyInfoForm.patchValue({
        Id: this.companyData?.Id,
        Name: this.companyData?.Name,
        Name_Local: this.companyData?.Name_Local,
        Region: this.companyData?.Region,
        Region_Local: this.companyData?.Region_Local,
        City: this.companyData?.City,
        City_Local: this.companyData?.City_Local,
        Vat_no: this.companyData?.Vatno,
        Country: this.companyData?.Country,
      });
    } else {
      this.companyInfoForm.reset();

      this.countryService.getCountries().subscribe((res: any) => {
        if (res) {
          this.user;
          this.countryList = res.sort((a: any, b: any) =>
            a.Name.localeCompare(b.Name)
          );
          this.countryList = this.countryList.filter((item) => {
            return this.user?.ShowInCountryList(item.Code, this.categoryId);
          });
          this.selectedCountry = this.countryList[0];

          this.companyData = this.appService.fetchCompanyAddDraft();
          this.companyData.Country = this.countryList[0].Code;
          this.companyInfoForm.patchValue({
            Id: this.companyData?.Id,
            Name: this.companyData?.Name,
            Name_Local: this.companyData?.Name_Local,
            Region: this.companyData?.Region,
            Region_Local: this.companyData?.Region_Local,
            City: this.companyData?.City,
            City_Local: this.companyData?.City_Local,
            Vat_no: this.companyData?.Vatno,
            Country: !this.companyData?.Country
              ? res[0].Code
              : this.companyData?.Country,
          });
        }
      });
    }
    this.validate();
  }

  companyInfoForm = inject(CompanyFormService).form.controls.companyInformation;

  cityFocus: boolean = false;
  companyFocus: boolean = false;

  get Name() {
    return this.companyInfoForm.get('Name');
  }

  get NameHasRequired() {
    return this.companyInfoForm.get('Name')?.hasValidator(Validators.required);
  }

  get City() {
    return this.companyInfoForm.get('City');
  }

  get HideVatNo() {
    return this.catFieldConfig['Company_HideVATno'] || '';
  }

  get HideName() {
    return this.catFieldConfig['Company_HideName'] || '';
  }

  get HideCity() {
    return this.catFieldConfig['Company_HideCity'] || '';
  }

  get HideLocalNameAndCity() {
    return this.catFieldConfig['Company_HideLocalNameAndCity'] || '';
  }

  validate() {
    this.subjectService.validationCompanySubject.subscribe((data) => {
      if (data) {
        this.cityFocus = true;
        this.companyFocus = true;
      }
    });
    var fieldConfig = this.appService.fetchCatgoryFieldConfig();

    if (fieldConfig['Company_HideLocalNameAndCity'] === true) {
      this.companyInfoForm.controls.Name.removeValidators(Validators.required);
      this.companyInfoForm.controls.Name.removeValidators(
        Validators.minLength(3)
      );
    } else {
      this.companyInfoForm.controls.Name.addValidators(Validators.required);
      this.companyInfoForm.controls.Name.addValidators(Validators.minLength(3));
    }
  }
  getErrorMsgs(control: any, fieldName: string) {
    if ((control?.invalid && control?.dirty) || control?.touched) {
      let val = '';
      if (control?.hasError('required')) {
        val = `${fieldName} is required.`;
      }

      if (control?.hasError('minlength')) {
        val = `${fieldName} must be at least 3 characters long..`;
      }
      return val;
    } else return '';
  }

  getFormDetails() {
    // console.log(this.companyInfoForm);
    return this.companyInfoForm;
  }

  getFormValue() {
    // console.log(this.companyInfoForm);
    console.log(this.Name);
    return this.companyInfoForm.value;
  }

  getLabelText(labelId: string, infoBlockId: string) {
    return this.appService.getLabelText(labelId, infoBlockId);
  }

  getPopupHelpText(labelId: string, infoBlockId: string) {
    return this.appService.getPopupHelpText(labelId, infoBlockId);
  }

  convertToCEST(date: string): string {
    if (date) {
      return formatDate(date + 'Z', 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0200');
    }
    return '';
  }

  saveDraft() {
    this.companyData.Id = this.companyInfoForm.get('Id')?.value;
    this.companyData.Name = this.companyInfoForm.get('Name')?.value;
    this.companyData.Name_Local = this.companyInfoForm.get('Name_Local')?.value;
    this.companyData.Region = this.companyInfoForm.get('Region')?.value;
    this.companyData.Region_Local =
      this.companyInfoForm.get('Region_Local')?.value;
    this.companyData.City = this.companyInfoForm.get('City')?.value;
    this.companyData.City_Local = this.companyInfoForm.get('City_Local')?.value;
    this.companyData.Vatno = this.companyInfoForm.get('Vat_no')?.value;
    this.companyData.Country = this.companyInfoForm.get('Country')?.value;

    if (this.companyInfoForm.dirty) {
      this.companyData.IsUpdated = true;
    }
    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(this.companyData, this.myId);
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }

  changeCountry(event: any) {
    if (event.detail.value) {
      this.companyInfoForm.patchValue({
        Country: event.detail.value,
      });
      this.saveDraft();

      this.subjectService.emitCompanyCountryChange(event.detail.value);
    }
  }

  copyElement(controlName: string) {
    let eleValue = this.companyInfoForm.get(controlName)?.value;
    navigator.clipboard.writeText(eleValue);
    this.utilService.alert('Copied to clipboard', '');
  }
}

export interface Country {
  Code: string;
  Name: string;
}
