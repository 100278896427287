<form [formGroup]="companyInfoForm" (change)="saveDraft()">
  @if(this.mode == 'print'){
  <h4>{{ this.Name?.value }}</h4>
  }
  <div class="grid grid-cols-1 md:grid-cols-3">
    <div class="inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("CompanyId", infoBlockId)
          }}<span class="text-red-600">*</span>
        </p>
        <div>
          <tds-tooltip
            placement="right"
            text="{{ getPopupHelpText('CompanyId', infoBlockId) }}"
            selector="#scania-tooltip"
            offset-distance="0"
            offset-skidding="0"
            mouse-over-tooltip="true"
            trigger="hover"
          >
          </tds-tooltip>
          <tds-tooltip
            placement="left"
            text="Click to Copy"
            selector="#scaniaCopy-tooltip"
            offset-distance="8"
            offset-skidding="0"
            mouse-over-tooltip="true"
            trigger="hover"
          >
          </tds-tooltip>
          <tds-icon
            ngDefaultControl
            name="copy"
            class="mt-5 mr-1 text-[#a8a8a9] cursor-pointer"
            id="scaniaCopy-tooltip"
            size="15"
            (click)="copyElement('Id')"
          ></tds-icon>
          <tds-icon
            name="info"
            class="mt-5 text-[#B0B7C4]"
            id="scania-tooltip"
            size="15"
          ></tds-icon>
        </div>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="Id"
          readOnly="true"
          id="Id"
          style="opacity: 40%"
        >
        </tds-text-field>
      </div>
    </div>

    <div class="inputField" style="overflow: visible">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("Country", infoBlockId) }}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('Country', infoBlockId) }}"
          selector="#Country-tooltip"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="Country-tooltip"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-dropdown
          ngDefaultControl
          name="dropdown"
          size="sm"
          open-direction="down"
          formControlName="Country"
          id="Country"
          [defaultValue]="companyData?.Country"
          style="width: 100%"
          (tdsChange)="changeCountry($event)"
          filter="true"
        >
          @for (country of countryList; track $index) {
          <tds-dropdown-option value="{{ country.Code }}">{{
            country.Name
          }}</tds-dropdown-option>
          }
        </tds-dropdown>
      </div>
    </div>

    <div *ngIf="!HideVatNo" class="inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("VATNo", infoBlockId) }}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('VATNo', infoBlockId) }}"
          selector="#vatno-tooltip"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="vatno-tooltip"
          size="15"
        ></tds-icon>
      </div>
      <div class="">
        <tds-text-field
          ngDefaultControl
          size="sm"
          formControlName="Vat_no"
          id="Vat_no"
          max-length="50"
        >
        </tds-text-field>
      </div>
    </div>

    <div *ngIf="!HideName" class="inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("Name", infoBlockId)
          }}<span *ngIf="NameHasRequired" class="text-red-600">*</span>
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('Name', infoBlockId) }}"
          selector="#companyname-tooltip"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="companyname-tooltip"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="Name"
          id="Name"
          (tdsFocus)="companyFocus = true"
          (tdsBlur)="companyFocus = true"
          [state]="
            Name?.invalid && (Name?.dirty || Name?.touched || companyFocus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(Name, 'Company Name', companyFocus)
          "
        >
        </tds-text-field>
      </div>
    </div>

    <div *ngIf="!HideCity" class="inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("City", infoBlockId)
          }}<span class="text-red-600">*</span>
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('City', infoBlockId) }}"
          selector="#City-tooltip"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="City-tooltip"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="City"
          id="City"
          max-length="255"
          (tdsFocus)="cityFocus = true"
          (tdsBlur)="cityFocus = true"
          [state]="
            City?.invalid && (City?.dirty || City?.touched || cityFocus)
              ? 'error'
              : 'success'
          "
          [helper]="utilService.getErrorMsgs(City, 'City', cityFocus)"
        >
        </tds-text-field>
      </div>
    </div>

    <div class="inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mb-0 pb-0 mt-5">
          {{ getLabelText("Region", infoBlockId) }}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('Region', infoBlockId) }}"
          selector="#Region-tooltip"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="Region-tooltip"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="Region"
          id="Region"
          max-length="255"
        >
        </tds-text-field>
      </div>
    </div>

    <div class="inputField" style="height: 110px">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-1 mb-0 pb-0">
          {{ getLabelText("NameLocal", infoBlockId) }}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('NameLocal', infoBlockId) }}"
          selector="#companylocal-tooltip"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-1 text-[#B0B7C4]"
          id="companylocal-tooltip"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="Name_Local"
          id="Name_Local"
          max-length="255"
        ></tds-text-field>
      </div>
    </div>

    <div *ngIf="!HideLocalNameAndCity" class="inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-1 mb-4 pb-0">
          {{ getLabelText("CityLocal", infoBlockId) }}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('CityLocal', infoBlockId) }}"
          selector="#citylocal-tooltip"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-1 text-[#B0B7C4]"
          id="citylocal-tooltip"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="City_Local"
          id="City_Local"
          max-length="255"
        >
        </tds-text-field>
      </div>
    </div>

    <div class="inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mb-4 pb-0 mt-1">
          {{ getLabelText("RegionLocal", infoBlockId) }}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('RegionLocal', infoBlockId) }}"
          selector="#regionlocal-tooltip"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-1 text-[#B0B7C4]"
          id="regionlocal-tooltip"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="Region_Local"
          max-length="255"
        >
        </tds-text-field>
      </div>
    </div>
  </div>

  <!-- <div
    class="flex-col flex-wrap w-full md:w-1/3 sm:w-1/3 xl:w-1/3 2xl:w-1/3 inline-flex"
  >
    
  </div>

  <div
    class="flex-col flex-wrap w-full md:w-1/3 sm:w-1/3 xl:w-1/3 2xl:w-1/3 inline-flex"
  >
   
  </div> -->

  @if(this.mode !=='Add'){
  <div
    class="flex-col flex-wrap w-full md:w-1/3 sm:w-1/3 xl:w-1/3 2xl:w-1/3 inline-flex"
  >
    <div class="m-2 inputField" style="overflow: visible; width: max-content">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("LastUpdated", infoBlockId) }}
          {{ getLabelText("LastUpdatedBy", infoBlockId) }}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('LastUpdated', infoBlockId) }}"
          selector="#lastUpdated-tooltip"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="lastUpdated-tooltip"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <p class="tds-detail-03">
          {{ convertToCEST(companyData?.Lastupdated) }} by
          <span class="font-semibold">{{ companyData?.Lastupdatedby }}</span>
        </p>
      </div>
    </div>
  </div>
  }
</form>
