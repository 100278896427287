import {
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TegelModule } from '@scania/tegel-angular';
import { CompanyFormService } from '../../../company/company-form.service';
import { AppService } from '../../../app.service';
import { UtilsService } from '../../utils.service';
import { SubjectService } from '../../../services/subject.service';

@Component({
  selector: 'app-bank-information',
  standalone: true,
  imports: [TegelModule, ReactiveFormsModule],
  templateUrl: './bank-information.component.html',
  styleUrl: './bank-information.component.css',
})
export class BankInformationComponent implements OnChanges, OnInit {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() infoBlockId: string = '';
  constructor(
    public appService: AppService,
    public utilService: UtilsService,
    private subjectService: SubjectService
  ) {}

  bankInfoForm = inject(CompanyFormService).form.controls.bankInfoForm;
  nameFocus: boolean = false;
  cityFocus: boolean = false;
  accountNoFocus: boolean = false;
  biCodeFocus: boolean = false;
  ibanFocus: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.bankInfoForm.patchValue({
        bankName: this.companyData?.CompanyBankInformation?.Bankname,
        bankCity: this.companyData?.CompanyBankInformation?.Bankcity,
        bankAccountNo: this.companyData?.CompanyBankInformation?.bankaccountno,
        swiftBiCode: this.companyData?.CompanyBankInformation?.swiftbiccode,
        iban: this.companyData?.CompanyBankInformation?.iban,
      });
    } else {
      this.bankInfoForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      this.bankInfoForm.patchValue({
        bankName: this.companyData?.CompanyBankInformation?.Bankname,
        bankCity: this.companyData?.CompanyBankInformation?.Bankcity,
        bankAccountNo: this.companyData?.CompanyBankInformation?.bankaccountno,
        swiftBiCode: this.companyData?.CompanyBankInformation?.swiftbiccode,
        iban: this.companyData?.CompanyBankInformation?.iban,
      });
    }
  }

  ngOnInit(): void {
    this.subjectService.validationCompanySubject.subscribe((data) => {
      if (data) {
        this.nameFocus = true;
        this.cityFocus = true;
        this.accountNoFocus = true;
        this.biCodeFocus = true;
        this.ibanFocus = true;
      }
    });
  }
  getFormDetails() {
    // console.log(this.companyInfoForm);
    return this.bankInfoForm;
  }

  get BankName() {
    return this.bankInfoForm.get('bankName');
  }

  get BankCity() {
    return this.bankInfoForm.get('bankCity');
  }

  get BankAccountNo() {
    return this.bankInfoForm.get('bankAccountNo');
  }

  get BankSwiftBiCode() {
    return this.bankInfoForm.get('swiftBiCode');
  }

  get BankIban() {
    return this.bankInfoForm.get('iban');
  }

  getLabelText(labelId: string, infoBlockId: string) {
    return this.appService.getLabelText(labelId, infoBlockId);
  }

  getPopupHelpText(labelId: string, infoBlockId: string) {
    return this.appService.getPopupHelpText(labelId, infoBlockId);
  }

  saveDraft() {
    if (!this.companyData.CompanyBankInformation)
      this.companyData.CompanyBankInformation = {};

    this.companyData.CompanyBankInformation.Bankname =
      this.bankInfoForm.get('bankName')?.value;
    this.companyData.CompanyBankInformation.Bankcity =
      this.bankInfoForm.get('bankCity')?.value;
    this.companyData.CompanyBankInformation.bankaccountno =
      this.bankInfoForm.get('bankAccountNo')?.value;
    this.companyData.CompanyBankInformation.swiftbiccode =
      this.bankInfoForm.get('swiftBiCode')?.value;
    this.companyData.CompanyBankInformation.iban =
      this.bankInfoForm.get('iban')?.value;
    if (this.bankInfoForm.dirty) {
      this.companyData.CompanyBankInformation.IsUpdated = true;
    }
    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}
