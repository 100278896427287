<form [formGroup]="companyLocationForm" (change)="saveDraft()">
  <div
    class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 mx-3 mb-4 mr-5"
  >
    <div class="inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("Lat", infoBlockId)
          }}<span class="text-red-600" *ngIf="locationRequired">*</span>
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('Lat', infoBlockId) }}"
          selector="#lat-tooltip"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="lat-tooltip"
          size="15"
        ></tds-icon>
      </div>
      <div class="w-full">
        <tds-text-field
          ngDefaultControl
          type="text"
          size="sm"
          no-min-width
          formControlName="Latitude"
          (tdsChange)="getTimeZone()"
          (tdsFocus)="latitudeFocus = true"
          (tdsBlur)="latitudeFocus = true"
          placeholder="Enter Latitude"
          [helper]="getErrorMsgs(Latitude, 'Latitude', latitudeFocus)"
          [state]="
            Latitude?.invalid &&
            (Latitude?.dirty || Latitude?.touched || latitudeFocus)
              ? 'error'
              : 'success'
          "
        >
        </tds-text-field>
      </div>
    </div>
    <div class="inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("Long", infoBlockId)
          }}<span class="text-red-600" *ngIf="locationRequired">*</span>
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('Long', infoBlockId) }}"
          selector="#lng-tooltip"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="lng-tooltip"
          size="15"
        ></tds-icon>
      </div>
      <div class="w-full">
        <tds-text-field
          ngDefaultControl
          type="text"
          size="sm"
          no-min-width
          formControlName="Longitude"
          placeholder="Enter Longitude"
          (tdsChange)="getTimeZone()"
          (tdsFocus)="longitudeFocus = true"
          (tdsBlur)="longitudeFocus = true"
          [helper]="getErrorMsgs(Longitude, 'Longitude', longitudeFocus)"
          [state]="
            Longitude?.invalid &&
            (Longitude?.dirty || Longitude?.touched || longitudeFocus)
              ? 'error'
              : 'success'
          "
        >
        </tds-text-field>
      </div>
    </div>
    <div class="inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("TimeZone", infoBlockId) }}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('TimeZone', infoBlockId) }}"
          selector="#tz-tooltip"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="tz-tooltip"
          size="15"
        ></tds-icon>
      </div>
      <div class="w-full">
        <tds-text-field
          ngDefaultControl
          type="text"
          size="sm"
          no-min-width
          formControlName="TimeZoneId"
          placeholder=""
          (tdsChange)="getTimeZone()"
        >
        </tds-text-field>
      </div>
    </div>
    <div class="mt-5 lg:mt-9">
      <tds-button
        type="button"
        variant="primary"
        size="sm"
        text="Pin location on map"
        id="location-modal-button"
        style="width: 85%"
      >
        <tds-icon slot="icon" size="16px" name="pin"></tds-icon>
      </tds-button>
      <tds-tooltip
        placement="right"
        text="{{ locationInfoText }}"
        selector="#loc-tooltip"
        offset-distance="8"
        offset-skidding="0"
        mouse-over-tooltip="true"
        trigger="hover"
      >
      </tds-tooltip>
      <tds-icon
        name="info"
        class="text-[#B0B7C4] relative"
        style="top: -30px"
        id="loc-tooltip"
        size="15"
      ></tds-icon>
    </div>
  </div>
</form>

<tds-modal
  header="View or change location"
  selector="#location-modal-button"
  id="my-modal"
  size="md"
  actions-position="sticky"
  prevent="true"
  closable="true"
  (tdsClose)="cancelSelectedPosition()"
>
  <google-map
    [center]="center"
    [zoom]="zoom"
    width="97%"
    class="h-fit"
    (mapClick)="getLocation($event)"
    [options]="options"
    slot="body"
    draggable="true"
  >
    <map-marker
      [position]="center"
      [options]="markerOptions"
      (mapDragend)="getLocation($event)"
    >
    </map-marker>
  </google-map>
  <span slot="actions" class="tds-u-flex tds-u-gap2">
    <tds-button
      data-dismiss-modal
      size="md"
      text="Use this position"
      variant="primary"
      (click)="setSelectedPosition()"
    ></tds-button>
    <tds-button
      data-dismiss-modal
      size="md"
      text="Cancel"
      variant="secondary"
      (click)="cancelSelectedPosition()"
    ></tds-button>
  </span>
</tds-modal>
