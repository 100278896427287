<form [formGroup]="additionalInformationForm" (change)="saveDraft()">
  <div class="flex flex-row overflow-auto no-scrollbar">
    <div class="p-2 w-full">
      <div>
        <tds-textarea
          ngDefaultControl
          row="5"
          size="sm"
          formControlName="Administrative_issues"
          (tdsFocus)="textFocus = true"
          (tdsBlur)="textFocus = true"
          [helper]="
            utilService.getErrorMsgs(
              AdditionalInformation,
              'Other Information',
              textFocus
            )
          "
          [state]="
            AdditionalInformation?.invalid &&
            (AdditionalInformation?.dirty ||
              AdditionalInformation?.touched ||
              textFocus)
              ? 'error'
              : 'success'
          "
          max-length="512"
        >
        </tds-textarea>
      </div>
    </div>
  </div>
</form>
